import facebook from '../assets/facebook.png'
import instagram from '../assets/instagram.png'
import paypal from '../assets/paypal.png'
import twitter from '../assets/twitter.png'
import venmo from '../assets/vimeo.png'

let buttonLinks = {
    facebook: 'https://www.facebook.com/',
    twitter: 'https://twitter.com/?lang=en',
    venmo:'https://venmo.com/',
    instagram:'https://www.instagram.com/',
    paypal: 'https://www.paypal.com/'
}

let imgLinks ={
    facebook: facebook,
    twitter: twitter,
    venmo: venmo,
    paypal: paypal,
    instagram: instagram
}


export default {buttonLinks, imgLinks}
