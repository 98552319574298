import react from 'react'
import links from '../links/links'

 export default function Button({name, buttonLink, imgLink}){

    return(
        <div className="container">
            <div>
                <a href={buttonLink}>
                    <img src={imgLink} alt="logo-image0-alt" className="logo-image"/>
                </a>

            </div>
            <a href={buttonLink} className={'link-stylez'}>
            <div className={'button-container'} >
                    <div>
                       <h1 className="title"> {name} </h1>
                    </div>



            </div>
        </a>
        </div>
    )
}