import './App.css';
import Button from './components/Button'
import links from './links/links.js'
import facebook from './assets/facebook.png'
import instagram from './assets/instagram.png'
import paypal from './assets/paypal.png'
import twitter from './assets/twitter.png'
import venmo from './assets/vimeo.png'
import PhotoWall from './components/PhotoWall.js'
import Gallery from "./components/PhotoWall.js";
import {useEffect, useState} from "react";
function App() {
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const updateWidthAndHeight = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    };
    useEffect(() => {
        window.addEventListener("resize", updateWidthAndHeight);
        return () => window.removeEventListener("resize", updateWidthAndHeight);
    },[]);
  return (
    <div className="App">

     <div className={"list-parent"}>
         <div className={'main-content'} >
             <div className={'landing-title'} >
                 <h1 className={'landing-title-header'}>Welcome to the LandingPage!</h1>
             </div>
             <div className={'landing-content-main'}>
                 Well, go thy way; thou shalt not from this grove
                 Till I torment thee for this injury.
                 My gentle Puck, come hither. Thou rememb’rest
                 Since once I sat upon a promontory,
                 And heard a mermaid on a dolphin’s back
                 Uttering such dulcet and harmonious breath
                 That the rude sea grew civil at her song
                 And certain stars shot madly from their spheres
                 To hear the sea-maid’s music.

                 That very time I saw, but thou couldst not,
                 Flying between the cold moon and the earth,
                 Cupid all arm’d. A certain aim he took
                 At a fair vestal, thronèd by the west,
                 And loosed his love-shaft smartly from his bow
                 As it should pierce a hundred thousand hearts.
                 But I might see young Cupid’s fiery shaft
                 Quenched in the chaste beams of the watery moon;
                 And the imperial votress passèd on,
                 In maiden meditation, fancy-free.
                 Yet marked I where the bolt of Cupid fell.
                 It fell upon a little western flower,
                 Before milk-white, now purple with love’s wound:
                 And maidens call it ‘love-in-idleness’.
                 Fetch me that flower; the herb I showed thee once.
                 The juice of it, on sleeping eyelids laid,
                 Will make or man or woman madly dote
                 Upon the next live creature that it sees.
                 Fetch me this herb, and be thou here again
                 Ere the leviathan can swim a league. -Oberon (Act 2 Scene 1)
             </div>

         </div>
         <div className="list-child">

             <li className={"list-link"}>
                <Button
                    name={'facebook'}
                    buttonLink={links.buttonLinks.facebook}
                    imgLink={facebook}
                />
             </li>
             <li className={"list-link"}>
                 <Button
                     name={'twitter'}
                     buttonLink={links.buttonLinks.twitter}
                     imgLink={twitter}
                 />
             </li>
             <li className={"list-link"}>
                 <Button
                     name={'venmo'}
                     buttonLink={links.buttonLinks.venmo}
                     imgLink={venmo}
                 />
             </li>
             <li className={"list-link"}>
                 <Button
                     name={'instagram'}
                     buttonLink={links.buttonLinks.instagram}
                     imgLink={instagram}
                 />
             </li >
             <li className={"list-link"}>
                 <Button
                     name={'PayPal'}
                     buttonLink={links.buttonLinks.paypal}
                     imgLink={paypal}
                 />
             </li >




         </div>
         <div className={'photo-wall-container'}>
             <Gallery />
         </div>
     </div>


    </div>
  );
}

export default App;
