import React from 'react'
import PhotoAlbum from "react-photo-album";
import img1 from '../images/img1.jpg'
import img2 from '../images/img2.jpg'
import img3 from '../images/img3.jpg'
import img4 from '../images/img4.jpg'
import img5 from '../images/img5.jpg'
import img6 from '../images/img6.jpg'

const photos = [
    { src: img2, width: 650, height: 500 },
    { src: img1, width: 600, height: 400 },
    { src: img3, width: 650, height: 800 },
    { src: img4, width: 650, height: 600 },
    { src: img5, width: 650, height: 700 },
    { src: img6, width: 650, height: 700 },
    { src: img1, width: 600, height: 400 },
    { src: img3, width: 650, height: 800 },
    { src: img4, width: 650, height: 600 },
    { src: img5, width: 650, height: 700 },
    { src: img6, width: 650, height: 700 },
    { src: img1, width: 600, height: 400 },
    { src: img3, width: 650, height: 800 },
    { src: img4, width: 650, height: 600 },


];

export default function Gallery() {
    return <PhotoAlbum layout="masonry" photos={photos} />;
}